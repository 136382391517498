@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-black{
    -webkit-text-stroke: 2px #000000;
  }

  .icon-fixed-size {
    width: 32px;
    height: 32px;
  }
  
}
